@import "~providiobase/skin/skin";
@import "../utilities/globalmixins";

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    background-color: var(--skin-background-color-1);
    color: var(--skin-main-text-color-1) !important;
}


.btn-primary {
    background-color: #{var(--skin-btn-background-color)};
    border-color: #{var(--skin-btn-border-color)};
    color: var(--skin-btn-text-color);
}

.btn-primary:hover {
    color: var(--skin-btn-text-color);
    border-color: var(--skin-btn-background-hover);
    background-color: var(--skin-btn-background-hover);
}

.btn-primary:focus {
    outline: none;
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:disabled:hover  {
    background-color: var(--skin-primary-color-1);
    border-color: var(--skin-primary-color);
    color: var(--skin-primary-color-invert-1);
    opacity: 0.65;
}

.btn-outline-primary:hover {
    color: var(--skin-primary-color-invert-1);
    background-color: var(--skin-primary-color-1);
    border-color: var(--skin-primary-color-1);
}

.btn-primary,
.hero-banner .btn {
    text-transform: capitalize;
    @include border-radius(0.255rem);

    &:hover:not(:disabled) {
        opacity: 0.9;
    }
}

.navbar .nav-item.dropdown .dropdown-menu.show {
    .dropdown-item > .dropdown-link {
        @include media-breakpoint-up(md) {
            color: var(--skin-menu-color-2);
        }
    }
}

.mobile-right-container {
    @include media-breakpoint-down(sm) {
        position: absolute;
        right: 10px;
        top: 25px;
        z-index: 10;
        width: 4rem;
    }

    .minicart {
        float: right;
    }

    .mobile-header-login {
        float: left;
    }
}

// site specific changes

.product-tile:hover .image-container .quickview:hover {
    color: var(--skin-link-color-inverse);
}

.btn-outline-primary {
    color: var(--skin-btn-background-color);
    border-color: var(--skin-btn-background-color);
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

//fonts helper classes
.text-nunito {
    font-family: var(--skin-body-font) !important;
}

.text-birds {
    font-family: var(--skin-secondary-font) !important;
}

.add-to-cart-messages {
    z-index: 9;
    top: 30%;
}

// PD textBox 
.storepage {
    .editorialRichText-component-container {

        h1 {
            font-size: 3.5rem;
            letter-spacing: -1.2pt;
            color: var(--color-sea-green);
        }

        h3 {
            font-size: 1.25rem;
            line-height: 1.2;
            text-transform: uppercase;
            letter-spacing: 2pt;
            font-weight: 700;
            padding: 1rem 0;

            br {
                display: none;
            }
        }
    }
}

.btn-tertiary {
    background-color: var(--skin-btn-tertiary-background-color);
    border-color: var(--skin-btn-tertiary-border-color);
    color: var(--skin-btn-tertiary-text-color) !important;
}

.btn-tertiary:hover {
    border-color: var(--skin-btn-tertiary-background-hover);
    background-color: var(--skin-btn-tertiary-background-hover);
    color: var(--skin-btn-tertiary-text-color) !important;
}

.btn-tertiary:focus {
    outline: none;
}

.btn-tertiary.disabled,
.btn-tertiary:disabled {
    border-color: var(--skin-btn-tertiary-background-hover);
    background-color: var(--skin-btn-tertiary-background-hover);
    color: var(--skin-btn-tertiary-text-color) !important;
    opacity: 0.65;
}

.btn-outline-tertiary:hover {
    border-color: var(--skin-btn-tertiary-background-hover);
    background-color: var(--skin-btn-tertiary-background-hover);
    color: var(--skin-btn-tertiary-text-color) !important;
}

.product-detail .attributes {

    @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-right: 0;
    }
}

.search-banner {
    background-color: #efefef;
}

.fulfillment-status-row  a {
    color: var(--color-blue) !important;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.my-account a:hover {
    text-decoration: underline;
}

.greyout {
    pointer-events: none;
    opacity: 0.65;
}

*:focus-visible{
    outline: none !important;
}
.form-control:focus {
    outline: none !important;
}

.leading-lines .start-lines::before {
    content: "";
}

.tracking-url a {
    color: var(--color-blue)!important;
    text-decoration: underline;
}

.start-lines,
.end-lines {
    p {
        margin-bottom: 0;
    }
}
