@import "../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "~base/skin/skin";
@import "../utilities/globalmixins";

html {
    scroll-behavior: smooth;
    font-size: $base-font-size;
}

.main-menu {
    background-color: var(--skin-banner-background-color-1);
    border-bottom: 1px solid var(--skin-input-border-color);
}

a {
    &:hover {
        text-decoration: var(--skin-link-decoration-style);
    }
}

.navbar,
.navbar .nav-item,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.dropdown-menu,
.dropdown-menu a {
    color: var(--skin-menu-color-1);
}

.navbar .nav-item.dropdown .dropdown-menu.show {
    background-color: var(--skin-selectbox-background-color-1);

    a,
    .nav-item {
        color: var(--skin-menu-color-1);
    }

    .dropdown-item > .dropdown-link {
        @include media-breakpoint-up(md) {
            color: var(--skin-menu-color-2);
        }

        + ul a {
            color: var(--skin-menu-color-1);
        }
    }
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    background-color: var(--skin-menu-color-1-invert);
    color: var(--skin-link-color-1) !important;
}

.multilevel-dropdown .dropdown-item ul.dropdown-menu .dropdown-link {
    color: var(--color-dark-gray);
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    color: var(--skin-menu-color-1) !important;
    background-color: none;
}

.nav-item > .nav-link {
    color: var(--skin-menu-color-1);
}

.btn {
    font-size: 0.75rem;
    text-transform: uppercase;
}

.btn-lg {
    font-size: $base-font-size;
}

.btn-primary {
    background-color: #{var(--skin-btn-background-color)};
    border-color: #{var(--skin-btn-border-color)};
    color: var(--skin-btn-text-color);
}

.btn-primary:hover,
.btn-primary:active {
    color: var(--skin-btn-text-color);
    border-color: var(--skin-btn-background-hover);
    background-color: var(--skin-btn-background-hover);
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: var(--skin-primary-color-1);
    border-color: var(--skin-primary-color);
    color: var(--skin-primary-color-invert-1);
    opacity: 0.65;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: var(--color-error-red);
}

.btn-outline-primary {
    background-color: var(--skin-background-color-1);

    &:hover {
        color: var(--skin-primary-color-invert-1);
        background-color: var(--skin-primary-color-1);
        border-color: var(--skin-primary-color-1);
    }
}

.btn {
    @include border-radius(0);
}

.site-header {
    font-family: var(--skin-header-font);
}

// input elements

.form-control {
    font-size: 0.8125rem;
    outline: none;
    outline-width: 0 !important;

    &:focus {
        @extend .form-control;
    }
}


.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    vertical-align: middle;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
}

select,
select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEVmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmaP/QSjAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 13px;
    -webkit-box-shadow: inset 0 -1px 0 #ddd;
    box-shadow: inset 0 -1px 0 #ddd;
    overflow: hidden;
    padding: 0.5rem 1rem 0.5rem 0;
    text-overflow: ellipsis;
}

.product-tile {
    border: 0;
}

*:focus-visible{
    outline: 2px dotted !important;
}